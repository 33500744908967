/* eslint-disable react/jsx-wrap-multilines */
import { LongTextContainer } from 'components/LongText/styles';
import NextImage from 'components/NextImage';
import { ReactNode } from 'react';
import { Accordion } from '../../components/Accordion';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { Text as Paragraph, Title } from '../../components/Typography';
import { ImagesService } from 'service/images/imagesService';

import { AccordianBodyContainer, ImageContainer, StyledImage, StyledText } from './styles';

import type { GenericAccordion as TGenericAccordion } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

interface IGenericAccordion extends ISharedTemplateProps {
  templateProps: TGenericAccordion;
}

export const GenericAccordion = ({ templateProps }: IGenericAccordion): ReactNode => {
  const name = 'GenericAccordion';
  const data = templateProps.genericAccordionItems;
  const numbered = templateProps.numbered;

  const accordionData = data?.map((item, index: number) => {
    let body = <></>;
    const title = (
      <Title tag="div" type="xs">
        {item.title}
      </Title>
    );
    const collapsed = item?.collapsed;
    if (item.alignment === 'ImageRightTextLeft' || item.alignment === 'ImageLeftTextRight') {
      const isReverse = item.alignment === 'ImageRightTextLeft';
      body = (
        <AccordianBodyContainer $isReverse={isReverse} key={`${item?.title}-${index}-body`}>
          <StyledImage>
            {item.image?.asset[0] ? (
              <NextImage sources={ImagesService.getPictureSources(item.image?.asset[0])} alt="" />
            ) : null}
          </StyledImage>
          <StyledText $isReverse={isReverse}>
            {item.text?.html ? (
              <LongTextContainer>
                <Paragraph tag="div" type="md">
                  <div dangerouslySetInnerHTML={{ __html: item.text?.html }} />
                </Paragraph>
              </LongTextContainer>
            ) : null}
          </StyledText>
        </AccordianBodyContainer>
      );
    } else if (item.alignment === 'NoImage') {
      body = (
        <AccordianBodyContainer $isReverse={false} key={`${item?.title}-${index}-body`}>
          {item.text?.html ? (
            <GridItem colSpan={10}>
              <LongTextContainer>
                <Paragraph tag="div" type="md">
                  <div dangerouslySetInnerHTML={{ __html: item.text?.html }} />
                </Paragraph>
              </LongTextContainer>
            </GridItem>
          ) : null}
        </AccordianBodyContainer>
      );
    } else if (item.alignment === 'ImageFullWidth') {
      body = (
        <div className="sm:my-5 md:mt-0 md:mb-50" key={`${item?.title}-${index}-body`}>
          {item.image?.asset[0]?.url ? (
            <GridItem>
              <ImageContainer>
                <NextImage sources={ImagesService.getPictureSources(item.image?.asset[0])} alt="" />
              </ImageContainer>
            </GridItem>
          ) : null}
        </div>
      );
    }
    return {
      title,
      body,
      collapsed,
      id: item.title,
    };
  });

  return (
    <Grid>
      <GridRow columns={16}>
        <GridItem colStart={2} colSpan={14}>
          <Accordion name={name} data={accordionData} $isNumbered={numbered} />
        </GridItem>
      </GridRow>
    </Grid>
  );
};
