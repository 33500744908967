import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AccordianBodyContainerTW = tw.div`
  ml-0
  md:ml-76
  my-5
  md:mt-0
  md:mb-50
`;

export const AccordianBodyContainer = styled(AccordianBodyContainerTW as any)<{
  $isReverse: boolean;
}>`
  display: flex;
  width: 70.69%;
   {
    ${(props) => (props.$isReverse ? 'flex-direction: row-reverse;' : 'flex-direction: row;')}
  }
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
  @media print {
    break-inside: avoid;
  }
`;

export const StyledImage = styled.div`
  width: 49.23%;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
export const StyledText = styled.div<{ $isReverse: boolean }>`
  width: 49.23%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
